<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-title>
        {{ $t('trips') }}
        <v-spacer />
        <v-autocomplete
          v-model="filter.cityFrom"
          :items="cities"
          dense
          outlined
          :label="$t('cityFrom')"
          class="mx-1"
          item-text="name"
          item-value="id"
          clearable
          @input="options.page= 1, fetchAllItems()"
        />
        <v-autocomplete
          v-model="filter.cityTo"
          :items="cities"
          dense
          outlined
          :label="$t('cityTo')"
          class="mx-1"
          item-text="name"
          item-value="id"
          clearable
          @input="options.page= 1, fetchAllItems()"
        />
        <v-select
          v-model="filter.status"
          :items="tripStatus"
          dense
          outlined
          :label="$t('status')"
          class="mx-1"
          item-text="name"
          item-value="value"
          clearable
          @input="options.page= 1, fetchAllItems()"
        />
      </v-card-title>
      <v-data-table
        :search="filter.search"
        :loading="dataLoading"
        :headers="headers"
        :items="list"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :options.sync="options"
        :server-items-length="total"
        :page-count="numberOfPages"
      >
        <template v-slot:[`item.shipments`]="{ item }">
          <span
            @click="showShipment(item.shipments)"
          >
            <v-chip class="clickable">
              {{ item.shipments.length }}
            </v-chip>

          </span>
        </template>
        <template v-slot:[`item.driver`]="{ item }">
          <span
            v-if="item.driver && item.driver.name"
            @click="viewDriverData(item.driver)"
          >
            <v-chip
              class="clickable"
              :loading="loading"
            >
              {{ item.driver.name }}
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span
            v-for="status in tripStatus"
            :key="status.id"
          >
            <span v-if="item.status === status.id">
              {{ status.name }}
            </span>
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                outlined
                class="mx-2"
                color="orange"
                v-bind="attrs"
                v-on="on"
                @click="sendNotification(item)"
              >
                <v-icon>
                  mdi-email
                </v-icon>
              </v-btn>
            </template>
            {{ $t('notify') }}
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                outlined
                class="mx-2"
                color="teal"
                v-bind="attrs"
                v-on="on"
                @click="confirmSMS(item)"
              >
                <v-icon>
                  mdi-message-processing
                </v-icon>
              </v-btn>
            </template>
            <span>Send SMS</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="shipmentDialog">
      <v-card>
        <v-card-title>
          {{ $t('shippmentData') }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="dataLoading"
            :headers="shipmentHeader"
            :items="shipments"
          >
            <template v-slot:[`item.status`]="{ item }">
              <span
                v-for="status in shipmentStatus"
                :key="status.id"
              >
                <span v-if="item.status === status.id">
                  {{ status.name }}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="driverDialog"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('driverData') }}
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="dataLoading"
            :headers="driverHeader"
            :items="driverData"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click="driverDialog = false, driverData = []"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="notifyDialog"
      max-width="500"
    >
      <v-card
        v-model="driverData"
        class="text-center"
      >
        <base-material-card
          title="Notification Details"
          color="orange"
        >
          <v-card-title class="mt-8">
            <v-text-field
              v-model="notification.title"
              counter="25"
              outlined
              label="Notification Title"
              class="notification-title"
            />
            <v-spacer />

            <v-icon
              aria-label="Close"
              @click="notifyDialog = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text>
            <v-textarea
              v-model="notification.body"
              outlined
              name="input-7-4"
              label="Notification Body"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              outlined
              class="my-1 mx-1"
              :loading="loading"
              :disabled="disabled"
              @click="sendNotifi(notification)"
            >
              {{ $t('send') }}
            </v-btn>
            <v-btn
              color="error"
              outlined
              @click="notifyDialog = false"
            >
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="smsDialog"
      max-width="500"
    >
      <v-card
        class="text-center"
      >
        <base-material-card
          title="SMS Details"
          color="teal"
        >
          <v-card-title class="mt-8">
            <v-icon
              aria-label="Close"
              @click="smsDialog = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text>
            <v-textarea
              v-model="SMS"
              outlined
              type="text"
              name="input-7-4"
              label="SMS Details"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              outlined
              class="my-1 mx-1"
              :loading="loading"
              :disabled="disabled"
              @click="sendSMS(driver)"
            >
              {{ $t("send") }}
            </v-btn>
            <v-btn
              color="error"
              outlined
              @click="smsDialog = false"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
    <base-material-snackbar
      v-if="snackbar"
      v-model="snackbar"
      :type="success"
      v-bind="{
        [parsedDirection[0]]: true,
        [parsedDirection[1]]: true
      }"
    >
      {{ $t('massegeSentSuccss') }}
    </base-material-snackbar>
    <base-material-snackbar
      v-if="snackbar2"
      v-model="snackbar2"
      :type="error"
      v-bind="{
        [parsedDirection[0]]: true,
        [parsedDirection[1]]: true
      }"
    >
      {{ $t('somethingError') }}
    </base-material-snackbar>
  </v-container>
</template>
<script>
  import { ServiceFactory } from '../../../services/ServiceFactory'
  const tripsServices = ServiceFactory.get('trips')
  const citiesServices = ServiceFactory.get('cities')
  const driverServices = ServiceFactory.get('drivers')
  export default {
    data: (vm) => ({
      list: [],
      cities: [],
      loader: null,
      loading: false,
      disabled: false,
      dataLoading: false,
      shipmentDialog: false,
      driverDialog: false,
      notifyDialog: false,
      smsDialog: false,
      snackbar: false,
      snackbar2: false,
      SMS: '',
      success: 'success',
      error: 'error',
      direction: 'top center',
      page: 0,
      total: 0,
      numberOfPages: 1,
      options: {},
      notification: {
        title: '',
        body: '',
        payload: {
          driverId: '',
          clientId: '',
        },
      },
      aboutAppData: {
        text: '',
        image: '',
        media: null,
      },
      filter: {
        search: null,
        cityFrom: null,
        cityTo: null,
        deliveryEpochFrom: null,
        deliveryEpochTo: null,
        status: null,
      },
      selected: [],
      shipments: [],
      driverData: [],
      driver: {},
      headers: [
        {
          text: vm.$t('id'),
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: vm.$t('cityFrom'), sortable: false, value: 'cityFrom' },
        { text: vm.$t('cityTo'), value: 'cityTo', sortable: false },
        { text: vm.$t('startDate'), sortable: false, value: 'startDate' },
        { text: vm.$t('startTime'), value: 'startTime', sortable: false },
        { text: vm.$t('driver'), value: 'driver', sortable: false },
        { text: vm.$t('shipments'), value: 'shipments', sortable: false },
        { text: vm.$t('status'), value: 'status', sortable: false },
        { text: vm.$t('actions'), value: 'actions', sortable: false },
      ],
      shipmentHeader: [
        {
          text: vm.$t('id'),
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: vm.$t('cityFrom'), sortable: false, value: 'cityFrom' },
        { text: vm.$t('cityTo'), value: 'cityTo', sortable: false },
        { text: vm.$t('fromAddress'), sortable: false, value: 'fromAddress' },
        { text: vm.$t('toAddress'), value: 'toAddress', sortable: false },
        { text: vm.$t('status'), value: 'status', sortable: false },
      ],
      driverHeader: [
        {
          text: vm.$t('id'),
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: vm.$t('name'), sortable: false, value: 'name' },
        { text: vm.$t('mobile'), value: 'mobile', sortable: false },
        { text: vm.$t('carNumber'), sortable: false, value: 'carNumber' },
        { text: vm.$t('carModel'), value: 'carModel', sortable: false },
        // { text: vm.$t('status'), value: 'status', sortable: false },
      ],
      tripStatus: [
        { id: 0, name: vm.$t('tripStatus.OPEN'), value: 'OPEN' },
        { id: 1, name: vm.$t('tripStatus.RUNNING'), value: 'RUNNING' },
        { id: 2, name: vm.$t('tripStatus.COMPLETED'), value: 'COMPLETED' },
        { id: 3, name: vm.$t('tripStatus.CANCELLED'), value: 'CANCELLED' },
      ],
      shipmentStatus: [
        { id: 0, name: 'New' },
        { id: 1, name: 'With Driver' },
        { id: 2, name: 'Shipped' },
        { id: 3, name: 'In Way' },
        { id: 4, name: 'Delivered' },
        { id: 5, name: 'Canceled' },
        { id: 6, name: 'Driver Snipping' },
        { id: 7, name: 'Snipped' },
        { id: 8, name: 'Unconfirmed' },
        { id: 9, name: 'Rejected' },
      ],
    }),
    computed: {
      parsedDirection () {
        return this.direction.split(' ')
      },
    },
    watch: {
      options: {
        handler () {
          this.fetchAllItems()
        },
      },
      deep: true,
    },
    created () {
      this.fetchAllCities()
    },
    methods: {
      showShipment (data) {
        this.shipments = data
        this.shipmentDialog = true
      },
      async viewDriverData (data) {
        this.loading = true
        this.driverDialog = true
        const driver = await driverServices.getDriverById(data.id)
        if (driver.statusCode === 201) {
          this.driverData.push(driver.data)
        }
      },
      confirmSMS (item) {
        this.smsDialog = true
        this.driver = item
      },
      async fetchAllItems (p) {
        this.dataLoading = true
          var { page, itemsPerPage } = this.options
          var pageNumber = page - 1
        if (p) {
          page = p
          pageNumber = page - 1
        }
        console.log('page', page)
        console.log('pageNumber', pageNumber)
        const allData = await tripsServices.getTrips(itemsPerPage, page, pageNumber, this.filter)
        this.list = allData.data.trips
        this.total = allData.data.total
        console.log('this.total', this.total)
        this.numberOfPages = allData.data.pageCount
        this.dataLoading = false
      },
      async fetchAllCities () {
        this.dataLoading = true
        const allData = await citiesServices.getAllData()
        this.cities = allData
        this.dataLoading = false
      },
      sendNotification (driver) {
        this.notification.payload.driverId = driver.driver.id
        this.notification.payload.clientId = driver.driver.userId
        this.notifyDialog = true
      },
      async sendNotifi (notification) {
        this.disabled = true
        this.loading = true
        this.isloading = true
        const sendNotifiSucess = await driverServices.sendNotifi(notification.payload.clientId, notification)
        if (sendNotifiSucess) {
          this.snackbar = true
          this.notifyDialog = false
          this.disabled = false
          this.loading = false
          this.notification = {
            title: '',
            body: '',
            payload: {
              driverId: '',
              clientId: '',
            },
          }
        } else {
          this.snackbar2 = true
          this.disabled = false
          this.loading = false
        }
      },
      async sendSMS (driverData) {
        this.disabled = true
        this.loading = true
        const sendSMS = await driverServices.sendSMS(
          this.driver.driver.userId,
          this.SMS,
        )
        if (sendSMS) {
          this.snackbar = true
          this.smsDialog = false
          this.disabled = false
          this.loading = false
          this.SMS = ''
        } else {
          this.snackbar2 = true
          this.disabled = false
          this.loading = false
        }
      },
    },
  }
</script>
<style lang="scss">
.image-responsive{
    width: 70px;
    height: 70px;
    border-radius:7px ;
    margin-top: 5px;

}
.v-card .v-card--material__heading{
  top: 0px !important;
}
.clickable{
  cursor: pointer;
}
</style>
